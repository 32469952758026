export default {
    fonts: {
        regular: 'Suisse Regular, sans-serif',
        semibold: 'Suisse Semibold, sans-serif',
    },
    colors: {
        blue: '#0054FF',
        black: '#000000',
        white: '#FFFFFF',
        green: '#36FF9A',
        lightBlue: '#A4EAFF',
        grey55: '#8C8C8C',
        grey75: '#C0C0C0',
        grey97: '#F7F7F7',
        hoverBlue: '#0040C2',
    },
    paddings: {
        section: {
            vertical: '10%',
            horizontal: '6%',
        },
    },
};
