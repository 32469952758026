import React from "react";
import PropTypes from "prop-types";
import { FelaComponent } from 'react-fela';
import { StaticQuery, graphql } from "gatsby";
import { injectIntl, FormattedMessage } from 'react-intl';
import Img from "gatsby-image";

import style, { boxStyle } from './Footer.style';

const Footer = ({ children }) => (
    <StaticQuery
        query={graphql`
            query {
                bgImage: file(relativePath: { eq: "bv_pattern.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 200) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                logo: file(relativePath: { eq: "logo.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 250) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                triangle: file(relativePath: { eq: "ic-triangle.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 50) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                shape: file(relativePath: { eq: "ic-shape.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 50) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                rectangle: file(relativePath: { eq: "ic-rectangle.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 50) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                email: file(relativePath: { eq: "ic-email.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 50) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
            }
        `}
        render={data => (
            <FelaComponent style={style} image={data.bgImage.childImageSharp.fluid.src} as="section">
                <div className="flex-container flex-space">
                    <img className="footer-logo" src={data.logo.childImageSharp.fluid.src} />
                    <FelaComponent style={boxStyle}>
                        <Img fluid={data.triangle.childImageSharp.fluid} style={{
                            width: 28,
                            marginBottom: 16,
                        }} />
                        <h4><FormattedMessage id="footer.company" /></h4>
                        <p><FormattedMessage id="footer.street" /></p>
                        <p><FormattedMessage id="footer.city" /></p>
                        <p><FormattedMessage id="footer.state" /></p>
                    </FelaComponent>
                    <FelaComponent style={boxStyle}>
                        <Img fluid={data.shape.childImageSharp.fluid} style={{
                            width: 37,
                            marginBottom: 16,
                        }} />
                        <p><FormattedMessage id="footer.ic" /></p>
                        <p><FormattedMessage id="footer.dic" /></p>
                    </FelaComponent>
                    <FelaComponent style={boxStyle}>
                        <Img fluid={data.rectangle.childImageSharp.fluid} style={{
                            width: 36,
                            marginBottom: 16,
                        }} />
                        <a href="mailto:hello@wultra.com"><FormattedMessage id="footer.mail" />
                        <Img fluid={data.email.childImageSharp.fluid} className="mail-ic" style={{
                            width: 38,
                            marginTop: 6,
                        }} /></a>
                    </FelaComponent>
                </div>
            </FelaComponent>
        )}
    />

)

Footer.propTypes = {

}

export default injectIntl(Footer);
