const bgColor = '#F7F7F7';
const dotColor = '#DEDEDE';

const dotSize = '2px';
const dotSpace = '8px';

const style = () => ({
    paddingTop: 132,
    textAlign: 'left',

    '& h1': {
        width: '50%',
        zIndex: 1,
    },

    '& .backgroundImage': {
        position: 'relative',
        backgroundRepeat: 'repeat',
        backgroundSize: 'unset',
        overflow: 'visible',
        minHeight: '85vh',
        marginLeft: '5.5%',
        width: '94.6%',

        background:
		`linear-gradient(90deg, ${bgColor} 6px, transparent 1%) center,
		linear-gradient(${bgColor} 6px, transparent 1%) center, ${dotColor}`,
	    backgroundSize: `${dotSpace} ${dotSpace} !important`,
    },

    '@media (min-width: 769px) and (max-width: 1125px)': {
        paddingTop: 100,

        '& .backgroundImage': {
            minHeight: '65vh !important',
        },
    },

    '@media (min-height: 1020px) and (orientation: portrait)': {
        '& .backgroundImage': {
            minHeight: '45vh !important',
        }
    },

    '@media (max-width: 767px)': {
        paddingTop: 78,

        '& .backgroundImage': {
            minHeight: 'auto',
            marginLeft: 12,
            width: 'calc(100% - 12px)',
        },

        '& .gatsby-image-wrapper': {
            marginLeft: '13px !important',
        },

        '& h1': {
            width: '100%',
            marginTop: 0,
        },
    },

    '@media (min-width: 768px) and (max-width: 769px)': {
        paddingTop: 100,

        '& h1': {
            width: '70%',
            zIndex: 1,
            position: 'relative',
        },
    },
});

export const imgStyles = ({ }) => ({
    width: '70%',
    position: 'absolute',
    bottom: '-15%',
    right: '-2%',

    '@media (min-width: 450px) and (max-height: 730px)': {
        bottom: '-26%',
        width: '50%',
    },

    '@media (max-width: 767px)': {
        width: '100%',
        marginTop: '10%',
        marginBottom: '-25%',
        position: 'relative',
        right: '7%',
        width: '97vw',
    },

    '@media (min-width: 768px) and (max-width: 769px)': {
        width: '70%',
        marginTop: '10%',
        marginBottom: '-5%',
        right: '0%',
        position: 'absolute',
    },

    '@media (min-height: 730px) and (min-width: 600px)': {
        width: '55%',
    },

    // '@media (min-width: 760px and min-height: 730px)': {
    //     width: '15%',
    // },

    min1920px: {
        width: '52%',
        bottom: '-10%',
        right: '-2%',
    },

})


export default style;
