import React from "react";
import PropTypes from "prop-types";
import { FelaComponent } from 'react-fela';
import Img from "gatsby-image";

import style, { } from './IconBox.style';

const IconBox = ({ imageFluid, title, text, width, height, isLast }) => (
    <FelaComponent style={style} isLast={isLast} width={width} height={height} as="article">
        <Img fluid={imageFluid} className="iconImage" />
        <br />
        <h3>{title}</h3>
        <p dangerouslySetInnerHTML={{__html: text}} />
    </FelaComponent>
)

IconBox.propTypes = {
    imageFluid: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
}

export default IconBox;
