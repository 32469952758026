/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { FelaComponent } from 'react-fela';


import cs from 'react-intl/locale-data/cs';
import { IntlProvider, addLocaleData } from 'react-intl';
import messages from '../localization';

import Header from '../components/Header';
import Top from '../components/Top';
import Info from '../components/Info';
import Banks from '../components/Banks';
import Feedback from '../components/Feedback';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import withLayout from '../HOC/withLayout';
import SEO from './seo';

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}


addLocaleData([...cs]);

const style = ({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: theme.colors.yellow,
});

const Layout = ({ isFormSuccesfull, lang, isAlternativeVersion }) => {
    return(
        <StaticQuery
            query={graphql`
            query SiteTitleQuery {
                site {
                siteMetadata {
                    title
                }
                }
            }
            `}
            render={data => (
                <IntlProvider
                    locale={lang}
                    messages={messages[lang]}
                >
                    <FelaComponent style={style}>
                        <SEO />
                        <Header lang={lang} />
                        <Top isFormSuccesfull={isFormSuccesfull} />
                        <Banks />
                        <Info />
                        <Feedback />
                        <Contact isFormSuccesfull={isFormSuccesfull} />
                        <Footer />
                    </FelaComponent>
                </IntlProvider>
            )}
        />
    )
}

Layout.propTypes = {
  //children: PropTypes.node.isRequired,
}

export default withLayout(Layout);
