import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { FelaComponent } from 'react-fela';

import style from './LinkButton.style';

const LinkButton = ({ text, href, action }) => (
    <StaticQuery
      query={graphql`
        query {
          linkButton: file(relativePath: { eq: "link-button-sprite.png" }) {
            childImageSharp {
              fluid(maxWidth: 40) {
                ...GatsbyImageSharpFluid
              }
            }
          },
        }
      `}
      render={data => (
        <FelaComponent style={style}>
            <a href={href} onClick={action}>
                <div className="linkButton-img" style={{ backgroundImage: `url(${data.linkButton.childImageSharp.fluid.src})`}} />
                {text}
            </a>
        </FelaComponent>
      )}
    />
)

LinkButton.propTypes = {

}

export default LinkButton;
