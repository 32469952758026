import React from 'react';
import PropTypes from 'prop-types';
import { FelaRenderer, ThemeProvider } from 'react-fela';

import reset from '!css-loader!../styles/reset.css';
import vendors from '!css-loader!../styles/vendors.css';
import defaults from '!css-loader!../styles/default.css';

import theme from '../styles/theme';
import fonts from '../styles/fonts';

const withLayout = PageComponent => props => (
    <ThemeProvider theme={theme}>
        <FelaRenderer>
            {renderer => {
                fonts.forEach(font => {
                    console.log(font);
                    renderer.renderFont(font.name, font.files, font.options);
                });

                renderer.renderStatic(reset.toString());
                renderer.renderStatic(vendors.toString());
                renderer.renderStatic(defaults.toString());
                return (
                    <PageComponent {...props} />
                );
            }}
        </FelaRenderer>
    </ThemeProvider>
);

withLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default withLayout;
