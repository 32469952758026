const style = ({ theme, isLast, width, height, }) => ({
    width: '60%',
    padding: '12% 20%',
    textAlign: 'left',

    '& .iconImage': {
        width,
        height,
        display: 'inline-block',
    },

    '& p': {
        maxWidth: 250,
        color: theme.colors.white,
        fontSize: '0.8rem',
        lineHeight: '1.3rem',
    },

    '& h3': {
        marginTop: '1.2rem',
        marginBottom: '0.6rem',
        color: theme.colors.white,
    },

    '@media (min-width: 768px) and (max-width: 1125px)': {
        width: '35%',
        paddingRight: isLast ? 0 : '4%',

        '& .iconImage': {
            width: '90%',
            height: 'auto'
        },
    },

    '@media (max-width: 768px)': {
        width: '100%',
        padding: '12% 0%',
    },

    '@media (min-width: 768px)': {
        width: '28%',
        padding: '10% 0%',
        paddingBottom: 0,
        paddingRight: isLast ? 0 : '8%',
        display: 'inline-block',

        '& p': {
            fontSize: '0.9rem',
            lineHeight: '1.5rem',
        },
    },

    '@media (min-width: 1850px)': {
        width: '20%',
        padding: '10% 0%',
        paddingBottom: 0,
        paddingRight: isLast ? 0 : '10%',
        display: 'inline-block',
    },
});

export default style;
