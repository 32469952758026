const bgColor = '#FFFFFF';
const bgColorDark = '#F7F7F7';
const dotColor = '#DEDEDE';
const dotColorDark = '#e4e4e4';

const dotSize = '2px';
const dotSpace = '8px';

const style = ({ theme, image }) => ({
    //backgroundImage: `url(${image})`,
    background:
    `linear-gradient(90deg, ${bgColor} 6px, transparent 1%) center,
    linear-gradient(${bgColor} 6px, transparent 1%) center, ${dotColorDark}`,
    backgroundSize: `${dotSpace} ${dotSpace} !important`,
    backgroundPositionY: '16px',

    position: 'relative',
    padding: '8% 0',
    paddingBottom: '5%',
    marginLeft: '5.5vw',
    textAlign: 'center',

    '@media (max-width: 767px)': {
        padding: '15% 0',
        marginLeft: 13,
    },
});

export const innerStyle = ({ theme, image }) => ({
    // backgroundImage: `url(${image})`,
    background:
    `linear-gradient(90deg, ${bgColorDark} 6px, transparent 1%) center,
    linear-gradient(${bgColorDark} 6px, transparent 1%) center, ${dotColor}`,
    backgroundSize: `${dotSpace} ${dotSpace} !important`,

    position: 'absolute',
    top: '23%',
    height: '84%',
    width: '100%',

    '@media (max-width: 768px)': {
        top: '20%',
    },

    '@media (min-width: 1700px)': {
        top: '26%',
    },

    '@media (min-width: 2200px)': {
        top: '28%',
    },
});

export const formWrapperStyle = ({ theme }) => ({
    backgroundColor: theme.colors.white,
    width: '90%',
    maxWidth: 400,
    margin: '0 auto',
    padding: '4rem 2.3rem',
    position: 'relative',
    border: `1px solid #EEEEEE`,

    '@media (max-width: 768px)': {
        width: '70%',
        marginLeft: '-2%',
    },

    marginLeft: '-5.5vw',
    '@media (min-width: 2000px)': {
        marginLeft: '-8.5vw',
    },

    '@media (min-width: 1800px)': {
        maxWidth: 500,
    },

    display: 'inline-block',

    '& form': {
        width: '100%',
    },

    '& h2': {
        marginBottom: '2rem',
        textAlign: 'left',
    },

    '& p': {
        maxWidth: 'unset',
        margin: '20px 0',
    },

    '& label': {
        color: theme.colors.grey75,
    },

    '& .material-input': {
        fontFamily: theme.fonts.regular,
        fontSize: '0.9rem',
        lineHeight: '1.1rem',
        margin: '10px 0',
        width: '100%',
        color: theme.colors.grey75,
    },

    '& textarea': {
        // fontFamily: theme.fonts.regular,
        // border: `1px solid ${theme.colors.black}`,
        // padding: '12px 16px',
        // fontSize: '0.9rem',
        // lineHeight: '1.1rem',
        // margin: '20px 0',
        // width: 'calc(100% - 2*16px)',
        // color: theme.colors.grey75,
        // minHeight: 140,
    },

    '& input[type=submit]': {
        fontFamily: theme.fonts.regular,
        marginTop: 20,
        width: '100%',
        padding: '21px 0',
        background: theme.colors.blue,
        color: theme.colors.white,
        transition: 'all 0.3s ease',
        border: 0,
        borderRadius: 0,
    },

    '& input[type=submit]:hover': {
        background: theme.colors.hoverBlue,
    },

    '@media (min-width: 768px)': {

    },
});

export default style;
