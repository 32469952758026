import React, { useState } from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { createComponent, FelaComponent } from 'react-fela';
import { injectIntl, FormattedMessage } from 'react-intl';
import SwipeableViews from 'react-swipeable-views';

import style, { imgStyle, boxStyle, textStyle, authorStyle, companyStyle } from './Feedback.style';


const styles = {
    tabs: {
      background: '#fff',
    },
    slide: {
      padding: 15,
      minHeight: 100,
      color: '#fff',
    },
    slide1: {
      backgroundColor: '#FEA900',
    },
    slide2: {
      backgroundColor: '#B3DC4A',
    },
    slide3: {
      backgroundColor: '#6AC0FF',
    },
  };


  const ImgFela = createComponent(
    imgStyle,
    'div',
    ['isActive', 'img', 'onClick', 'width', 'height', 'isMobile', ]
);


const Banks = ({ }) => {
    const [activeTab, setActiveTab] = useState(0);
    return (
        <StaticQuery
        query={graphql`
            query {
                feedback1: file(relativePath: { eq: "feedback1.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                feedback2: file(relativePath: { eq: "feedback2.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                feedback3: file(relativePath: { eq: "feedback3.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                feedback4: file(relativePath: { eq: "feedback4.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                feedback1Person: file(relativePath: { eq: "feedback1Person.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                feedback2Person: file(relativePath: { eq: "feedback2Person.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                feedback3Person: file(relativePath: { eq: "feedback3Person.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                feedback4Person: file(relativePath: { eq: "feedback4Person.png" }) {
                    childImageSharp {
                        fluid(maxWidth: 300) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
            }
        `}
        render={data => (
            <FelaComponent style={style} as="section">
                <div className="fluid-container high-flex-container flex-column">
                    <h2><FormattedMessage id="feedback.title" /></h2>
                    <div className="high-flex-container flex-middle flex-centered">
                        <ImgFela /*onClick={() => setActiveTab(0)}*/ isActive={activeTab === 0} img={data.feedback1.childImageSharp.fluid.src} width={60} height={60} />
                        <ImgFela /*onClick={() => setActiveTab(1)}*/ isActive={activeTab === 1} img={data.feedback2.childImageSharp.fluid.src} width={136} height={40} />
                        <ImgFela /*onClick={() => setActiveTab(2)}*/ isActive={activeTab === 2} img={data.feedback3.childImageSharp.fluid.src} width={172} height={40} />
                        <ImgFela /*onClick={() => setActiveTab(3)}*/ isActive={activeTab === 3} img={data.feedback4.childImageSharp.fluid.src} width={182} height={31.5} />
                    </div>
                    <SwipeableViews index={activeTab} animateTransitions={false} /*onChangeIndex={(index) => setActiveTab(index)}*/>
                        <FelaComponent
                            style={boxStyle}
                            //isActive={activeTab === 0}
                        >
                            <ImgFela isMobile /*onClick={() => setActiveTab(0)}*/ isActive={activeTab === 0} img={data.feedback1.childImageSharp.fluid.src} width={60} height={60} />
                            <img src={data.feedback1Person.childImageSharp.fluid.src} />
                            <FelaComponent style={textStyle} as="p"><FormattedMessage id="feedback.sazka.text" /></FelaComponent>
                            <FelaComponent style={authorStyle} as="p"><FormattedMessage id="feedback.sazka.author" /></FelaComponent>
                            <FelaComponent style={companyStyle} as="p"><FormattedMessage id="feedback.sazka.company" /></FelaComponent>
                        </FelaComponent>
                        <FelaComponent
                            style={boxStyle}
                            //isActive={activeTab === 1}
                        >
                            <ImgFela isMobile /*onClick={() => setActiveTab(1)}*/ isActive={activeTab === 1} img={data.feedback2.childImageSharp.fluid.src} width={136} height={40} />
                            <img src={data.feedback2Person.childImageSharp.fluid.src} />
                            <FelaComponent style={textStyle} as="p"><FormattedMessage id="feedback.synotip.text" /></FelaComponent>
                            <FelaComponent style={authorStyle} as="p"><FormattedMessage id="feedback.synotip.author" /></FelaComponent>
                            <FelaComponent style={companyStyle} as="p"><FormattedMessage id="feedback.synotip.company" /></FelaComponent>
                        </FelaComponent>
                        <FelaComponent
                            style={boxStyle}
                            //isActive={activeTab === 2}
                        >
                            <ImgFela isMobile /*onClick={() => setActiveTab(2)}*/ isActive={activeTab === 2} img={data.feedback3.childImageSharp.fluid.src} width={172} height={40} />
                            <img src={data.feedback3Person.childImageSharp.fluid.src} />
                            <FelaComponent style={textStyle} as="p"><FormattedMessage id="feedback.tipsport.text" /></FelaComponent>
                            <FelaComponent style={authorStyle} as="p"><FormattedMessage id="feedback.tipsport.author" /></FelaComponent>
                            <FelaComponent style={companyStyle} as="p"><FormattedMessage id="feedback.tipsport.company" /></FelaComponent>
                        </FelaComponent>
                        <FelaComponent
                            style={boxStyle}
                            //isActive={activeTab === 3}
                        >
                            <ImgFela isMobile /*onClick={() => setActiveTab(3)}*/ isActive={activeTab === 3} img={data.feedback4.childImageSharp.fluid.src} width={182} height={31.5} />
                            <img src={data.feedback4Person.childImageSharp.fluid.src} />
                            <FelaComponent style={textStyle} as="p"><FormattedMessage id="feedback.chance.text" /></FelaComponent>
                            <FelaComponent style={authorStyle} as="p"><FormattedMessage id="feedback.chance.author" /></FelaComponent>
                            <FelaComponent style={companyStyle} as="p"><FormattedMessage id="feedback.chance.company" /></FelaComponent>
                        </FelaComponent>
                    </SwipeableViews>

                </div>
            </FelaComponent>
        )}
        />
    )
}

Banks.propTypes = {

}

export default injectIntl(Banks);
