import React, { useState } from "react";
import PropTypes from "prop-types";
import { FelaComponent } from 'react-fela';
import { StaticQuery, graphql } from "gatsby";
import { injectIntl, FormattedMessage } from 'react-intl';
import IconBox from './IconBox';

import style, { iconsStyle, whiteStyle } from './Info.style';


const Info = ({ intl }) => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    info1: file(relativePath: { eq: "info1.png" }) {
                        childImageSharp {
                            fluid(maxWidth: 250) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    },
                    info2: file(relativePath: { eq: "info2.png" }) {
                        childImageSharp {
                            fluid(maxWidth: 250) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    },
                    info3: file(relativePath: { eq: "info3.png" }) {
                        childImageSharp {
                            fluid(maxWidth: 250) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    },
                }
            `}
            render={data => (
                <React.Fragment>
                    <FelaComponent style={style} as="section">
                        <div className="fluid-container high-flex-container flex-column">
                            <h2><FormattedMessage id="info.title" /></h2>
                            <div className="high-flex-container">
                                <IconBox imageFluid={data.info1.childImageSharp.fluid} title={intl.formatMessage({ id: "info.first.title" })} text={intl.formatMessage({ id: "info.first.description" })} width={251} height={145} />
                                <IconBox imageFluid={data.info2.childImageSharp.fluid} title={intl.formatMessage({ id: "info.second.title" })} text={intl.formatMessage({ id: "info.second.description" })} width={239} height={145} />
                                <IconBox imageFluid={data.info3.childImageSharp.fluid} isLast title={intl.formatMessage({ id: "info.third.title" })} text={intl.formatMessage({ id: "info.third.description" })} width={251} height={145} />
                            </div>
                        </div>
                    </FelaComponent>
                </React.Fragment>
            )}
        />
    )
}

Info.propTypes = {

}

export default injectIntl(Info);
