export const style = ({ theme, isOnTop }) => ({
    textAlign: 'center',

    '@media (min-width: 1900px)': {
        '> div': {
            marginLeft: '-3.5vw',
        },
    },
});

export const imgStyle = ({ theme, img, width, height, isActive, isMobile }) => ({
    backgroundImage: `url(${img})`,
    backgroundSize: '100%',
    backgroundPositionY: 'unset', //isActive ? '100%' : 'unset',
    // transition: (!isActive || isMobile) ? 'none' : 'all 0.2s ease',
    width,
    height,
    //cursor: 'pointer',
    marginRight: isMobile ? 0 : '4rem',
    marginTop: '2rem',

    display: !isMobile ? 'flex' : 'none',

    '@media (max-width: 1125px)': {
        marginRight: isMobile ? 0 : '2rem',
    },

    '@media (max-width: 768px)': {
        display: isMobile ? 'flex' : 'none',
    },
});

export const boxStyle = ({ theme, isActive }) => ({
    // display: isActive ? 'flex' : 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& img': {
        width: 220,
        height: 220,
        marginTop: '3rem',
        marginBottom: '2rem',
    },
});

export const textStyle = ({ theme }) => ({
    fontSize: '1.2rem',
    lineHeight: '1.7rem',
    color: theme.colors.black,
    maxWidth: 600,
    marginBottom: '2.5rem',
});

export const authorStyle = ({ theme }) => ({
    fontSize: '1rem',
    lineHeight: '1.2rem',
    fontFamily: theme.fonts.semibold,
    color: theme.colors.black,
    letterSpacing: '0.2px',
});

export const companyStyle = ({ theme }) => ({
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: theme.colors.grey55,
});

export default style;
