
const bgColor = '#FFFFFF';
const dotColorDark = '#e4e4e4';

const dotSize = '2px';
const dotSpace = '8px';

const style = ({ theme, image }) => ({
    // backgroundImage: `url(${image})`,
    background:
    `linear-gradient(90deg, ${bgColor} 6px, transparent 1%) center,
    linear-gradient(${bgColor} 6px, transparent 1%) center, ${dotColorDark}`,
    backgroundSize: `${dotSpace} ${dotSpace} !important`,
    backgroundPositionY: '16px',

    paddingTop: '13%',
    paddingBottom: '8%',
    marginLeft: '5.5vw',

    '& .footer-logo': {
        width: 193,
        height: 43,
        display: 'inline-block',
        marginRight: '14%',
        marginLeft: 12,
    },

    '@media (max-width: 767px)': {
        marginLeft: 13,
        paddingTop: '11%',
        paddingBottom: '5%',

        '> div': {
            flexDirection: 'column',
        },

        '& .footer-logo': {
            margin: 30,
            marginTop: 50,
            marginLeft: 20,
        },
    },

    '@media (max-width: 768px)': {
        '& .footer-logo': {
            display: 'none',
        },

        '& .flex-space': {
            justifyContent: 'flex-start',
        },
    },

});

export const boxStyle = ({ theme }) => ({
    marginRight: '8%',
    display: 'inline-block',

    '& a': {
        color: theme.colors.black,
        textDecoration: 'none',
        display: 'block',
        fontSize: '0.9rem',
        lineHeight: '1.4rem',
    },

    '& a .mail-ic': {
        transition: 'all 0.15s ease',
    },

    '& a:hover .mail-ic': {
        marginLeft: 6,
    },

    '@media (max-width: 768px)': {
        margin: 30,
    },

    '& p': {
        fontSize: '0.9rem',
        lineHeight: '1.4rem',
    },
});

export default style;
