import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { createComponent, FelaComponent } from 'react-fela';
import { injectIntl, FormattedMessage } from 'react-intl';
import { DialogOverlay, DialogContent} from "@reach/dialog";

import style, { imgStyles } from './Top.style';
import LinkButton from '../LinkButton/';

import "@reach/dialog/styles.css";

const ImgFela = createComponent(
    imgStyles,
    'img',
    ['alt', 'src']
);

class Top extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
        isOpen: false
    };

}

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            bgImage: file(relativePath: { eq: "header_bg_pattern.png" }) {
              childImageSharp {
                fluid(maxWidth: 250) {
                  ...GatsbyImageSharpFluid
                }
              }
            },
            computer: file(relativePath: { eq: "header-bg.png" }) {
              childImageSharp {
                fluid(maxWidth: 2048) {
                  ...GatsbyImageSharpFluid
                }
              }
            },
          }
        `}
        render={data => (
          <FelaComponent style={style}>
              <div className="backgroundImage">
                  <div className="fluid-container" style={{ paddingBottom: 0 }}>
                      <h1><FormattedMessage id="top.title" /></h1>
                      <LinkButton text={<FormattedMessage id="top.howLink" />} action={() => this.setState({ isOpen: true }) } />
                      <ImgFela src={data.computer.childImageSharp.fluid.src} />
                      <DialogOverlay
                        style={{ background: "hsla(0, 100%, 100%, 0.9)", zIndex: 1000 }}
                        isOpen={ this.state.isOpen }
                        onDismiss={() => this.setState({ isOpen: false })}
                      >
                        <DialogContent style={{ boxShadow: '0px 10px 50px hsla(0, 0%, 0%, 0.33)', width: 640, height: 360 }}>
                          <a onClick={() => this.setState({ isOpen: false }) } style={{ position: "absolute", top: 20, right: 20 }}>
                            <FormattedMessage id="video.close" />
                          </a>
                          <div>
                            <iframe width="100%" height="360" src="https://www.youtube.com/embed/lBPHh6FOeZU?autoplay=1" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                          </div>
                        </DialogContent>
                      </DialogOverlay>
                  </div>
              </div>
          </FelaComponent>
        )}
      />
    );
  }
}

Top.propTypes = {

}

export default injectIntl(Top);
