export const style = ({ theme, isOnTop }) => ({
    textAlign: 'left',
    marginTop: '2rem',

    '& a': {
        color: theme.colors.blue,
        fontSize: '0.8rem',
        lineHeight: '0.9rem',
        textTransform: 'uppercase',
        letterSpacing: 1,
        display: 'flex',
        alignItems: 'center',
    },

    '& a:hover': {
        color: theme.colors.hoverBlue,

        '& .linkButton-img': {
            backgroundPositionY: '100%',
        },
    },

    '& .linkButton-img': {
        width: 40,
        height: 40,
        marginRight: 16,
    },

    '@media (max-width: 767px)': {

        '&': {
            display: 'none'
        },

        '& a': {
            fontSize: 16,
            lineHeight: '18px',
        },
    },
});

export default style;
