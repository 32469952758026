import SuisseRegular from "../fonts/SuisseIntlRegular.otf";
import SuisseSemibold from "../fonts/SuisseIntlSemiBold.otf";

export default [
    {
        name: 'Suisse Regular',
        files: [SuisseRegular],
        options: {
            fontStyle: 'normal',
            fontWeight: '400',
        },
    },
    {
        name: 'Suisse Semibold',
        files: [SuisseSemibold],
        options: {
            fontStyle: 'normal',
            fontWeight: '700',
        },
    },
];
