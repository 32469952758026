import React from "react";
import PropTypes from "prop-types";
import { FelaComponent } from 'react-fela';
import { StaticQuery, graphql } from "gatsby";
import TextField from '@material-ui/core/TextField';
import { injectIntl, FormattedMessage } from 'react-intl';

import style, { formWrapperStyle, innerStyle } from './Contact.style';

class Contact extends React.Component {
    componentDidMount() {
        const { isFormSuccesfull } = this.props;
        if (isFormSuccesfull) {
            this.successMessage.scrollIntoView();
            window.dataLayer.push({
                'event': 'InteractionForm',
                'eventCategory': 'Form',
                'eventAction': 'Submit',
                'eventLabel': 'Lead',
                'eventValue': ''
            });
        }
    }

    render() {
        const { isFormSuccesfull, intl } = this.props;
        return (
            <StaticQuery
                query={graphql`
                    query {
                        bgImage: file(relativePath: { eq: "bv_pattern.png" }) {
                            childImageSharp {
                                fluid(maxWidth: 250) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        },
                        bgImageDark: file(relativePath: { eq: "bv_pattern_dark.png" }) {
                            childImageSharp {
                                fluid(maxWidth: 250) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        },
                    }
                `}
                render={data => (
                    <div id="contact">
                        <FelaComponent style={style} image={data.bgImage.childImageSharp.fluid.src} as="section">
                            <FelaComponent style={innerStyle} image={data.bgImageDark.childImageSharp.fluid.src} as="section" />
                            <FelaComponent style={formWrapperStyle} id="contact">
                                <h2><FormattedMessage id="contact.title" /></h2>
                                <form action="https://formspree.io/hello@wultra.com" method="post">
                                    <TextField
                                        className="material-input"
                                        label={<FormattedMessage id="contact.subject" />}
                                        name="subject"
                                        required
                                        style={{ color: 'red' }}
                                    />
                                    <TextField
                                        className="material-input"
                                        label={<FormattedMessage id="contact.name" />}
                                        name="name"
                                        required

                                    />
                                    <TextField
                                        className="material-input"
                                        label={<FormattedMessage id="contact.email" />}
                                        name="mail"
                                        required

                                    />
                                    <TextField
                                        className="material-input"
                                        label={<FormattedMessage id="contact.message" />}
                                        name="message"
                                        multiline
                                        rows="4"

                                    />
                                    <input type="hidden" name="_next" value="#done" />
                                    <input type="submit" name="submit" value={intl.formatMessage({ id: "contact.submit" })} />
                                    {isFormSuccesfull &&
                                        <p ref={(el) => { this.successMessage = el; }} style={{ fontWeight: 'bold', maxWidth: '70%' }}>
                                        <FormattedMessage id="contact.success" />
                                        </p>
                                    }
                                    <br />
                                </form>
                            </FelaComponent>
                        </FelaComponent>
                    </div>
                )}
            />

        );
    }
}

Contact.propTypes = {

}

export default injectIntl(Contact);
