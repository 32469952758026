import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { injectIntl, FormattedMessage } from 'react-intl';
import { createComponent, FelaComponent } from 'react-fela';
import Img from "gatsby-image";

import style from './Banks.style';

const Banks = ({ }) => (
    <StaticQuery
      query={graphql`
        query {
          bank1: file(relativePath: { eq: "bank1.png" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          bank2: file(relativePath: { eq: "bank2.png" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          bank3: file(relativePath: { eq: "bank3.png" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          bank4: file(relativePath: { eq: "bank4.png" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          bank5: file(relativePath: { eq: "bank5.png" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          bank6: file(relativePath: { eq: "bank6.png" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          bank7: file(relativePath: { eq: "bank7.png" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          },
          bank8: file(relativePath: { eq: "bank8.png" }) {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          },
        }
      `}
      render={data => (
        <FelaComponent style={style} as="section">
            <div className="fluid-container high-flex-container flex-column">
                <h2><FormattedMessage id="banks.title" /></h2>
                <p><FormattedMessage id="banks.description" /></p>
                <div>
                    <img src={data.bank1.childImageSharp.fluid.src} style={{ marginRight: '5rem', width: 137, height: 60, }} />
                    <img src={data.bank2.childImageSharp.fluid.src} style={{ marginRight: '5rem', width: 167, height: 60, }} />
                    <img src={data.bank3.childImageSharp.fluid.src} style={{ marginRight: '5rem', width: 90, height: 70, }} />
                    <img src={data.bank4.childImageSharp.fluid.src} style={{ marginRight: '2.5rem', width: 248, height: 38, }} />
                    <img src={data.bank5.childImageSharp.fluid.src} style={{ marginRight: '5rem', width: 149, height: 60, }} />
                    <img src={data.bank6.childImageSharp.fluid.src} style={{ marginRight: '5rem', width: 189, height: 40, }} />
                    <img src={data.bank7.childImageSharp.fluid.src} style={{ marginRight: '2rem', width: 212, height: 40, }} />
                    <img src={data.bank8.childImageSharp.fluid.src} style={{ width: 206, height: 18, }} />
                </div>
            </div>
        </FelaComponent>
      )}
    />
)

Banks.propTypes = {

}

export default injectIntl(Banks);
