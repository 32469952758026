const style = ({ theme }) => ({
    backgroundColor: theme.colors.blue,
    margin: '0 5.5%',

    '& h2': {
        color: theme.colors.white,
    },

    '@media (max-width: 767px)': {
        marginRight: 0,
        marginLeft: 13,
        background: theme.colors.blue,
    },
});

export default style;
