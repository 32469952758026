import React from 'react';
import { FelaComponent, createComponent } from 'react-fela';
import { StaticQuery, graphql } from "gatsby"
import debounce from 'lodash.debounce';
import { injectIntl, FormattedMessage } from 'react-intl';

import { wrapperStyle, headerStyle, burgerStyle } from './Header.style';

const FelaBurger = createComponent(
    burgerStyle,
    'div',
    ['onClick', 'isOpen']
);
class Header extends React.Component {
    static DEBOUNCE_TIME = 1000 / 60;

    static SCROLL_OFFSET = 0;
    static DIRECTION_UP = 'up';
    static DIRECTION_DOWN = 'down';

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            headerHeight: null,
        };

        this.lastScrollTop = 0;

        this.ref = React.createRef();

        this.handleScroll = debounce(this.handleScrollUnbounced, Header.DEBOUNCE_TIME);
        this.handleResize = debounce(this.handleResizeUnbounced, Header.DEBOUNCE_TIME);
    }

    componentDidMount() {
        this.handleScroll();
        this.handleResize();

        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleDirection = () => {
        const direction = window.pageYOffset - this.lastScrollTop > 0 ? Header.DIRECTION_DOWN : Header.DIRECTION_UP;

        if (direction === Header.DIRECTION_DOWN && window.pageYOffset > 100) {
            this.setState({
                show: true,
            });
        } else {
            this.setState({
                show: false,
            });
        }

        this.lastScrollTop = window.pageYOffset;
    };

    handleScrollTop = () => {
        if (this.lastScrollTop > Header.SCROLL_OFFSET) {
            this.setState({
                isOffTop: false,
            });
        } else {
            this.setState({
                isOffTop: true,
            });
        }
    };

    handleScrollUnbounced = () => {
        this.handleDirection();
        this.handleScrollTop();
    };

    handleHeaderHeightChange = () => {
        this.setState({
            headerHeight: this.ref.current.clientHeight,
        });
    };

    handleResizeUnbounced = () => {
        this.handleHeaderHeightChange();
    };

    render() {
        const { show, headerHeight, isOpen } = this.state;
        const { lang } = this.props;
        return (
            <StaticQuery
                query={graphql`
                    query {
                        logoImage: file(relativePath: { eq: "logo.png" }) {
                            childImageSharp {
                            fluid(maxWidth: 2048) {
                                ...GatsbyImageSharpFluid
                            }
                            }
                        },
                    }
                `}
                render={data => (
                    <div>
                        <FelaComponent height={headerHeight} style={wrapperStyle}>
                            {({ className }) => (
                                <div className={className} ref={this.ref}>
                                    <FelaComponent style={headerStyle} as="header">
                                        <img src={data.logoImage.childImageSharp.fluid.src} style={{ maxHeight: 42, marginLeft: 12, }} />
                                        <ul>
                                            <li>
                                                <a href="/docs" target="_blank"><FormattedMessage id="header.developers" /></a>
                                            </li>
                                            <li>
                                                <a href="#contact"><FormattedMessage id="header.contact" /></a>
                                            </li>
                                            {/*                                             
                                            {lang === 'en'
                                                ? <li>
                                                    <a href="/">CZ</a>
                                                </li>
                                                : <li>
                                                    <a href="/en">EN</a>
                                                </li>
                                            } */}
                                        </ul>
                                    </FelaComponent>
                                </div>
                            )}
                        </FelaComponent>
                        <FelaComponent height={headerHeight} isFixed show={show} style={wrapperStyle}>
                            {({ className }) => (
                                <div className={className} ref={this.ref}>
                                    <FelaComponent  isOpen={isOpen} isFixed style={headerStyle} as="header">
                                        <img src={data.logoImage.childImageSharp.fluid.src} />
                                        <FelaBurger isOpen={isOpen} onClick={() => this.setState({ isOpen: !isOpen })}>
                                            <span className="line-up line"></span>
                                            <span className="line-down line"></span>
                                        </FelaBurger>
                                        <ul>
                                            <li>
                                                <a href="/docs" target="_blank"><FormattedMessage id="header.developers" /></a>
                                            </li>
                                            <li>
                                                <a href="#contact"><FormattedMessage id="header.contact" /></a>
                                            </li>
                                            {/*                                             
                                            {lang === 'en'
                                                ? <li>
                                                    <a href="/">CZ</a>
                                                </li>
                                                : <li>
                                                    <a href="/en">EN</a>
                                                </li>
                                            } */}
                                        </ul>
                                    </FelaComponent>
                                </div>
                            )}
                        </FelaComponent>
                    </div>
                )}
            />

        );
    }
}

export default injectIntl(Header);
