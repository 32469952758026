export const style = ({ theme, isOnTop }) => ({
    margin: '0 5.5%',

    '& .high-flex-container': {
        paddingRight: '2%',
        width: '89%',
    },

    '& p': {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        color: theme.colors.grey55,
        marginBottom: '2rem',
        marginTop: '1rem',
        maxWidth: '40%',
    },

    '& img': {
        marginTop: '3rem',
    },

    '@media (max-width: 600px)': {

        '& img': {
            display: 'block',
            marginTop: '4rem',
        },
    },

    '@media (max-width: 767px)': {
        marginRight: 0,
        marginLeft: 13,
        marginTop: 150,

        '& p': {
            maxWidth: '100%',
        },
    },

    '@media (max-width: 1125px)': {
        '& .gatsby-image-wrapper': {
            display: 'inline-block',
        },

        '& .high-flex-container': {
            display: 'block',
            textAlign: 'left',
        },

        '& p': {
            maxWidth: '70%',
        },
    },
});

export default style;
