export const wrapperStyle = ({ theme, show, height, isFixed }) => ({
    position: isFixed ? 'fixed' : 'absolute',
    display: isFixed ? 'block' : 'none',
    top: 0,

    min1125px: {
        top: (!isFixed || show) ? 0 : `-${height + 10}`,
        display: 'block',
        opacity: (!isFixed || show) ? 1 : 0,
    },

    zIndex: 999,
    background: theme.colors.white,

    transition: 'top 0.35s',

    boxSizing: 'border-box',
    width: '100%',
});

export const headerStyle = ({ theme, height, isFixed, isOpen }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',

    boxSizing: 'border-box',
    width: '100%',
    height: 'auto',
    padding: '1rem 5.5% 1rem 5.5%',

    background: theme.colors.white,
    // boxShadow: isFixed ? '0px 2px 2px 0px rgba(0,0,0,0.06)' : 'none',

    '& a': {
        color: theme.colors.blue,
        textTransform: 'uppercase',
        border: 'none',
        textDecoration: 'none',
        letterSpacing: '1px',
    },

    '& a:hover': {
        color: theme.colors.hoverBlue,
    },

    '& ul': {
        display: 'flex',
    },

    '& li': {
        margin: '0 15px',
    },

    '& img': {
        maxHeight: 42,
        marginLeft: 12,
    },

    min768px: {
        flexDirection: 'row',
        height: isFixed ? 90 : 132,
    },

    '@media (max-width: 767px)': {
        display: 'block',

        overflow: isOpen ? 'visible' : 'hidden',
        height: isOpen ? 'auto' : 90,
        padding: '24px 20px',
        // width: 'calc(100% - 2*20px)',

        '& a': {
            fontSize: 16,
            lineHeight: '18px',
        },
        '& ul': {
            display: 'block',
            textAlign: 'right',
            marginTop: 30,
        },
        '& li': {
            margin: '0 5px',
            marginTop: 38,
            marginBottom: 10,
        },
        '& img': {
            maxWidth: 193,
            marginLeft: 0,
        },
    },
});



export const burgerStyle = ({ theme, isOpen }) => ({
    display: 'none',

    '& .line': {
        width: 40,
        height: 3,
        backgroundColor: theme.colors.blue,
        display: 'block',
    },

    '& .line-up': {
        marginBottom: 8,
        transform: isOpen ? 'rotate(45deg) translate(2.5px,7.9px)' : '',
    },

    '& .line-down': {
        marginBottom: 8,
        transform: isOpen ? 'rotate(-45deg) translate(0,-5px)' : '',
    },

    '@media (max-width: 767px)': {
        display: 'inline-block',
        float: 'right',
    },
})
